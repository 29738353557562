<section [ngClass]="{ 'flu-charts__details': urlPath !== 'dashboard' }">
  <section class="d-flex mt-5 flex-column flex-lg-row gap-1">
    <section
      class="col-12 col-sm-12 col-md-12 col-lg-12 p-3 flu-charts__my-shipments"
      [ngClass]="{
        'col-xl-6': urlPath === 'dashboard',
        'col-xl-12': urlPath != 'dashboard'
      }"
    >
      <h3>{{ 'orderConfirmation.myShipments' | cxTranslate }}</h3>
      <div
        class="chart-container"
        [ngClass]="{ 'chartMargin-Width': urlPath === 'dashboard' }"
      >
        <div class="chart-percentage" *ngIf="totalDoses">
          <span>{{
            (dosesInChartDisplay / totalDoses) * 100 | number : '1.0-0'
          }}</span>
        </div>
        <canvas baseChart [data]="doughnutChartData" [type]="doughnutChartType" [options]="doughnutChartOptions">
        </canvas>
      </div>
      <section
        *ngIf="doughnutChartData.datasets.length!==0"
        class="d-flex mt-2"
        [ngClass]="{
          'dose-total-Section': true,
          'dose-total-Section-reservation-details': urlPath != 'dashboard'
        }"
      >
        <section>
          <img
            src="/assets/icons/saved-cart-icon-original.svg"
            alt="chart-image"
            [ngClass]="{
              'chart-image-css': true,
              'chart-image-reservation-details-css': urlPath != 'dashboard'
            }"
          />
        </section>

        <section
          [ngClass]="{
            'doseShip-row': true,
            'doseShip-row-reservation-details': urlPath != 'dashboard'
          }"
        >
          <span class="doseNum text-center"
            >{{ dosesInChartDisplay }} of {{ totalDoses }}</span
          >
        </section>
        <section
          [ngClass]="{
            'doeses-Shipped': true,
            'doeses-Shipped-reservation-details': urlPath != 'dashboard'
          }"
        >
          <span class="text-center"> doses {{ statusInChrtsDisplay }}</span>
        </section>
      </section>
    </section>
    <section class="overflow-auto">
      <table aria-describedby="cardDetailsTable" class="table">
        <thead>
          <tr class="header-row">
            <th scope="col" class="header">
              {{ 'orderConfirmation.shipmentStaus' | cxTranslate }}
            </th>
            <th scope="col" class="header">
              {{ 'orderConfirmation.shipDate' | cxTranslate }}
            </th>
            <th scope="col" class="header">
              {{ 'orderConfirmation.unitsDoses' | cxTranslate }}
            </th>
            <th scope="col" class="header">
              {{ 'orderConfirmation.tracking' | cxTranslate }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            class="content-row"
            *ngFor="let data of chartTableArray; let j = index"
            [ngClass]="{ 'alternate-Row': j % 2 === 0 }"
          >
            <td class="statusTd col-Divider">
              {{ data?.status }}
            </td>
            <td class="col-Divider">
              <span *ngIf="data?.shippingDate; else noShipping">
                {{ data?.shippingDate | date : 'dd/MM/yyyy' }}
              </span>
              <ng-template #noShipping>
                <span>-</span>
              </ng-template>
            </td>
            <td class="col-Divider">
              {{ data.totalQuantity }}/{{ data.doses }}
            </td>
            <td class="col-Divider">
              <span *ngIf="data?.trackingData; else emptyData">
                <a
                  class="tracking-Num-Span app-navigation-links"
                  href="unsafe:javascript:void(0)"
                  rel="{{ fullTrackingUrl }}"
                  type="external"
                  (click)="
                    navigateToTracking(
                      trackingIdValue?.trackingUrlId,
                      trackingIdValue?.trackingUrl
                    )
                  "
                  *ngFor="
                    let trackingIdValue of data?.trackingData;
                    let idIndex = index
                  "
                >
                  {{ trackingIdValue?.trackingUrlId }}
                  <span *ngIf="idIndex + 1 != data?.trackingData?.length"
                    >,</span
                  >
                </a>
              </span>

              <ng-template #emptyData>
                <span class="text-center">-</span>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </section>
</section>
