import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  GlobalMessageService,
  GlobalMessageType,
  LanguageService,
} from '@spartacus/core';
import { Subscription } from 'rxjs';
import { CustomRegistrationService } from '../../custom-registration.service';
import {
  globalErrorMsgEng,
  globalErrorMsgFr,
  siteLanguagesEng,
} from '../../registration.constants';

@Component({
  selector: 'app-account-information',
  templateUrl: './account-information.component.html',
})
export class AccountInformationComponent implements OnInit, OnDestroy {
  accountInfoForm: FormGroup;
  errorMsg = false;
  errorTypes: string[] = ['b2bCustomer.register.accountNumber.invalidType'];
  billToShipError = false;
  errorMessageText: string = globalErrorMsgEng;
  accountInfoSub: Subscription;
  captchaResp: string;

  constructor(
    private readonly accountRegService: CustomRegistrationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly globalMessage: GlobalMessageService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    const maxlengthChar = 20;
    const maxlengthSeven = 7;
    this.languageService.getActive().subscribe((langRes) => {
      this.errorMessageText =
        langRes === siteLanguagesEng ? globalErrorMsgEng : globalErrorMsgFr;
    });
    this.accountInfoForm = new FormGroup({
      accountNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]+$'),
        Validators.maxLength(maxlengthChar),
      ]),
      postalCode: new FormControl('', [
        Validators.required,
        Validators.pattern('^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] [0-9][A-Z][0-9]$'),
        Validators.maxLength(maxlengthSeven),
      ]),
      recaptchaReactive: new FormControl(null, Validators.required),
    });
  }

  validateAccountInfo() {
    this.accountInfoForm.markAllAsTouched();
    if (this.accountInfoForm.valid) {
      delete this.accountInfoForm.value?.recaptchaReactive;
      this.accountInfoSub = this.accountRegService
        .postValidateAccountInfo(this.captchaResp, this.accountInfoForm.value)
        .subscribe(
          (res) => {
            if (res === null) {
              this.errorMsg = false;
              const accountInfo = {
                header: 'userInformation',
                form: this.accountInfoForm,
              };
              this.accountRegService.sectionChange.next(accountInfo);
            }
          },
          (error) => {
            if (!!error) {
              this.checkErrors(error);
              this.errorMsg = true;
              window.scroll(0, 0);
              this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
              this.globalMessage.add(
                this.errorMessageText,
                GlobalMessageType.MSG_TYPE_ERROR
              );
              this.cdr.detectChanges();
            }
          }
        );
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  checkErrors(error) {
    if (!!error?.error?.errors && error?.error?.errors.length > 0) {
      this.billToShipError =
        error?.error?.errors[0].message === this.errorTypes[0] ? true : false;
    }
  }

  resolved(captchaResponse: string) {
    this.captchaResp = captchaResponse;
  }

  ngOnDestroy() {
    this.accountInfoSub?.unsubscribe();
  }
}
